import Vue from 'vue';
import VueRouter from 'vue-router'; // 引入vue-router
import Productos from '@/components/Productos.vue';
import Nosotros from '@/components/Nosotros.vue';
import Privacid from '@/components/Privacid.vue';
import Eliminardatos from '@/components/Eliminardatos.vue';
import Soporte from '@/components/Soporte.vue';

Vue.use(VueRouter); // 在Vue中注册VueRouter

const routes = [
  {
    path: '/',
    name: 'nuestrosproductos',
    component: Productos,
  },
  {
    path: '/sobrenosotros',
    name: 'sobrenosotros',
    component: Nosotros,
  },
  {
    path: '/politicadeprivacidad',
    name: 'politicadeprivacidad',
    component: Privacid,
  },
  {
    path: '/soportetecnico',
    name: 'soportetecnico',
    component: Soporte,
  },
  {
    path: '/eliminardatos',
    name: 'eliminardatos',
    component: Eliminardatos,
  },
];

const router = new VueRouter({
  mode: 'history', // 路由模式
  routes,
});

export default router; //导出router
