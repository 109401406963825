<template>
  <div>
    <div class="Soporte">
      <img src="../assets/data.png" class="texti" />
      <div class="right">
        <div class="Punto">Documento de Soporte Técnico</div>
        <div class="Xonaca">
          <img src="../assets/time.png" alt="" />
          <span>Fecha de última actualización: 6 de diciembre de 2024</span>
        </div>
        <div class="desc-title">1. Resumen</div>
        <div class="desc-content">
          ¡Gracias por usar la aplicación AyudaPréstamo! Nuestra aplicación está
          diseñada para ayudarle a calcular fácilmente el monto del préstamo,
          generar un plan de pagos y ofrecer soporte en la gestión financiera. A
          través de esta aplicación, puede revisar rápidamente la información
          del préstamo y planificar pagos adecuados.
        </div>
        <div class="desc-title">2. Instalación y Configuración Inicial</div>
        <div class="desc-content">
          <div class="subtitle">Instalación</div>
          <div class="row">Abra la App Store y busque “AyudaPréstamo”.</div>
          <div class="row">
            Haga clic en descargar e instalar la aplicación.
          </div>
          <div class="row">
            Después de la instalación, toque el ícono de la aplicación para
            abrirla.
          </div>
          <div class="subtitle">Primer Uso</div>
          <div>
            Al iniciar la aplicación por primera vez, deberá ingresar la
            información del préstamo. La aplicación generará automáticamente un
            plan de pagos. Puede consultar y editar esta información en
            cualquier momento.
          </div>
        </div>
        <div class="desc-title">3. Funciones Principales</div>
        <div class="desc-content">
          <div class="row">
            Cálculo de Préstamos: Ingrese el monto del préstamo, la tasa de
            interés y otros detalles para que la aplicación calcule
            automáticamente el plan de pagos.
          </div>
          <div class="row">
            Ver Plan de Pagos: Obtenga información detallada sobre el monto del
            pago mensual, el interés total, entre otros datos.
          </div>
          <div class="row">
            Historial: Guarde y consulte el historial de sus préstamos para una
            mejor gestión financiera.
          </div>
        </div>
        <div class="desc-title">4. Preguntas Frecuentes (FAQ)</div>
        <div class="desc-content">
          <div class="faq">P: ¿Cómo modificar la información del préstamo?</div>
          <div class="faq">
            R: Ingrese a la pantalla principal de la aplicación y haga clic en
            el botón “Editar” para modificar la información del préstamo.
          </div>
          <div class="faq">P: ¿Es segura mi información del préstamo?</div>
          <div class="faq">
            R: Todos los datos del préstamo se almacenan de forma encriptada
            para asegurar su privacidad.
          </div>
          <div class="faq">
            P: ¿Qué hacer si no puedo generar el plan de pagos?
          </div>
          <div class="faq">
            R: Verifique que toda la información del préstamo esté completa y
            sea correcta. Si todo está en orden, intente nuevamente.
          </div>
        </div>
        <div class="desc-title">5. Contáctenos</div>
        <div class="desc-content">
          <div>
            Si tiene alguna pregunta o problema con la aplicación, no dude en
            contactarnos:
          </div>
          <div class="row">Correo electrónico: support@ayudaprestamo.com</div>
          <div class="row">Sitio web oficial: www.ayudaprestamo.com</div>
        </div>
        <div class="desc-title">6. Requisitos del Sistema</div>
        <div class="desc-content">
          <div class="row">Sistema operativo: iOS 12.0 o superior.</div>
          <div class="row">
            Dispositivos: Compatible con iPhone, iPad y iPod touch.
          </div>
          <div class="row">
            Conexión de red: Se requiere conexión a Internet.
          </div>
        </div>
        <div class="desc-title">7. Privacidad y Seguridad</div>
        <div class="desc-content">
          Valoramos su privacidad y todos los datos personales se manejarán de
          acuerdo con nuestra política de privacidad y se protegerán
          adecuadamente.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Privacid',
  components: {},
  data() {
    return {
      index: 1,
    };
  },
  methods: {},
};
</script>

<style scoped>
.Soporte {
  padding: 40px 360px;
  background-color: white;
  display: flex;
}
.texti {
  width: 294px;
  height: 440px;
  margin-right: 40px;
}
.right img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Punto {
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  margin-bottom: 10px;
}
.Xonaca {
  font-weight: 400;
  font-size: 20px;
  color: #6450f0;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.desc-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
  color: #565656;
}
.desc-content {
  font-size: 20px;
  color: #565656;
  margin-bottom: 10px;
}
.subtitle {
  padding-left: 30px;
  color: #000000;
  margin: 5px 0;
}
.row {
  padding-left: 20px;
  position: relative;
}
.row::after {
  content: ' ';
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #000000;
  border-radius: 50%;
  top: 10px;
  left: 0;
}
.faq {
  padding-left: 30px;
  margin-bottom: 2px;
}
.faq:nth-child(2n + 1) {
  font-weight: 600;
}
</style>
