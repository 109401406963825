<template>
  <div>
    <div class="Nosotros">
      <img src="../assets/wenjian.png" class="texti" />
      <div class="right">
        <div class="Punto">Política de Privacidad</div>
        <div class="Xonaca">
          <img src="../assets/time.png" alt="" />
          <span>Fecha de última actualización: 6 de diciembre de 2024</span>
        </div>
        <div class="Bienvenido">
          Bienvenido a nuestra aplicación de cálculo de préstamos (en adelante,
          la "Aplicación"). Valoramos su privacidad y nos comprometemos a
          proteger su información personal. Por favor, lea detenidamente esta
          Política de Privacidad para comprender cómo recopilamos, usamos y
          protegemos sus datos.
        </div>
        <div class="desc-title">1. Información que recopilamos</div>
        <div class="desc-content">
          <div>
            Al utilizar esta Aplicación, podemos recopilar la siguiente
            información:
          </div>
          <div>
            Datos ingresados por el usuario: Incluyendo el monto del préstamo,
            la tasa de interés y el plazo.
          </div>
          <div>
            Información del dispositivo: Podemos registrar identificadores de su
            dispositivo y la dirección IP para optimizar las funciones de la
            Aplicación.
          </div>
        </div>
        <div class="desc-title">2. Uso de la información</div>
        <div class="desc-content">
          <div>
            La información recopilada se utiliza únicamente para los siguientes
            propósitos:
          </div>
          Generar y mostrar su plan de pagos del préstamo; Proporcionar
          resultados de cálculo precisos y consejos financieros; Mejorar las
          funciones y la experiencia del usuario en la Aplicación.
        </div>
        <div class="desc-title">3. Almacenamiento de datos</div>
        <div class="desc-content">
          Todos los datos se almacenan de manera segura en nuestros servidores
          para generar los planes de pagos. Implementamos medidas técnicas
          razonables para proteger la seguridad de los datos y prevenir accesos
          o divulgaciones no autorizadas.
        </div>
        <div class="desc-title">4. Compartición de datos</div>
        <div class="desc-content">
          <div>
            No compartiremos sus datos con terceros, salvo en los siguientes
            casos:
          </div>
          <div>Cumplimiento de requisitos legales y normativos;</div>
          <div>Con su consentimiento explícito.</div>
        </div>
        <div class="desc-title">5. Derechos del usuario</div>
        <div class="desc-content">
          <div>Usted tiene derecho a:</div>
          <div>
            Acceder, modificar o eliminar su información personal en cualquier
            momento;
          </div>
          Solicitar que dejemos de usar sus datos.
        </div>
        <div class="desc-title">6. Retención de datos</div>
        <div class="desc-content">
          Sus datos se conservarán mientras utilice la Aplicación y durante el
          tiempo exigido por la ley. Una vez cumplido el plazo de retención,
          eliminaremos o anonimizaremos sus datos de manera segura.
        </div>
        <div class="desc-title">7. Contáctenos</div>
        <div class="desc-content">
          <div>
            Si tiene alguna pregunta o inquietud, puede contactarnos a través
            de:
          </div>
          <div>Correo electrónico: servicio@textipunto.com</div>
          <div>Horario de atención: 24 horas al día, todos los días</div>
        </div>
        <div class="desc-title">
          8. Actualizaciones de la Política de Privacidad
        </div>
        <div class="desc-content">
          Podemos actualizar esta Política de Privacidad según los requisitos
          legales o para mejorar nuestros servicios. La versión más reciente
          siempre estará disponible en la Aplicación.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Privacid',
  components: {},
  data() {
    return {
      index: 1,
    };
  },
  methods: {},
};
</script>

<style scoped>
.Nosotros {
  padding: 40px 360px;
  background-color: white;
  display: flex;
}
.texti {
  width: 294px;
  height: 440px;
  margin-right: 40px;
}
.right img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Punto {
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  margin-bottom: 10px;
}
.Xonaca {
  font-weight: 400;
  font-size: 20px;
  color: #6450f0;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.Bienvenido {
  font-size: 20px;
  margin-bottom: 20px;
  color: #565656;
}
.desc-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
  color: #565656;
}
.desc-content {
  font-size: 20px;
  color: #565656;
  margin-bottom: 10px;
}
</style>
