<template>
  <div id="app">
    <layouts></layouts>
  </div>
</template>

<script>
import layouts from '@/components/layouts.vue';
export default {
  name: 'App',
  components: {
    layouts,
  },
};
</script>

<style>
@import url('./assets/reset.css');
</style>
