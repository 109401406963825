<template>
  <div>
    <transition name="slide-fade" @after-enter="hideElement">
      <div class="success" v-if="showSuccess">
        <img src="@/assets/yes.png" alt="" />
        <div>Eliminado con éxito</div>
      </div>
    </transition>
    <div class="Nosotros">
      <img src="../assets/datos.png" class="texti" />
      <div class="right">
        <div class="Punto">Eliminar datos</div>
        <div class="dedicamos">
          Si desea eliminar todos sus datos en AyudaPréstamo, por favor ingrese
          su número de teléfono y haga clic en "Enviar". Nuestro sistema
          eliminará automáticamente sus datos relacionados según su solicitud.
          Lamentamos mucho cualquier inconveniente y agradecemos su comprensión.
          Si desea darnos otra oportunidad, por favor, espere a que sigamos
          mejorando.
        </div>
        <div class="ipnut-box">
          <div class="c-input">
            <div class="addNumber">+52</div>
            <input
              v-model="phone"
              type="number"
              class="phone"
              oninput="if(value.length>10)value=value.slice(0,10)"
              placeholder="Por favor ingrese el número de teléfono móvil de 10 dígitos"
            />
          </div>
          <div
            class="Enviar"
            :class="phone.length === 10 ? 'btn-a' : ''"
            @click="Confirmar"
          >
            Enviar
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Nosotros',
  components: {},
  data() {
    return {
      index: 1,
      phone: '',
      showSuccess: false,
    };
  },
  methods: {
    Confirmar() {
      if (this.phone.length === 10) {
        this.showSuccess = true;
        this.phone = '';
      }
    },
    hideElement() {
      setTimeout(() => {
        this.showSuccess = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.Nosotros {
  padding: 40px 360px;
  background-color: white;
  display: flex;
}
.texti {
  width: 294px;
  height: 440px;
  margin-right: 40px;
}
.right {
  display: flex;
  flex-direction: column;
}
.right img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Punto {
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  margin-bottom: 10px;
}
.Xonaca {
  font-weight: 400;
  font-size: 20px;
  color: #6450f0;
  display: flex;
  align-items: center;
}
.dedicamos {
  font-weight: 400;
  font-size: 20px;
  color: #565656;
}
.eliminar {
  padding: 40px 360px;
  background-color: white;
  font-weight: 400;
  font-size: 24px;
  color: #ff0000;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
}
.ipnut-box {
  margin-top: auto;
}
.c-input {
  width: 400px;
  height: 64px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #c8c8c8;
  margin-bottom: 40px;
  padding: 12px 0;
  box-sizing: border-box;
  display: flex;
}
.phone {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 20px;
  color: #565656;
}
/* 隐藏输入框上下箭头 */
.phone::-webkit-inner-spin-button,
.phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 兼容Firefox */
.phone[type='number'] {
  -moz-appearance: textfield;
}

.addNumber {
  height: 100%;
  border-right: 1px solid #c8c8c8;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  color: #565656;
  margin-right: 20px;
}
.Enviar {
  width: 400px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  background: #96affa;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
.btn-a {
  background: #6450f0;
  color: white;
}

.success {
  width: 250px;
  height: 52px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #6450f0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.success img {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

.success div {
  font-weight: 400;
  font-size: 16px;
  color: #565656;
}
.slide-fade-enter-active {
  transition: opacity 0.5s;
}
.slide-fade-enter {
  opacity: 0;
}
</style>
