<template>
  <div>
    <div class="Nosotros">
      <img src="../assets/texti.png" class="texti" />
      <div class="right">
        <div class="Punto">Texti Punto, S.A. de C.V.</div>
        <div class="Xonaca">
          <img src="../assets/dingwei.png" alt="" />
          <span
            >Dirección:Calle 24 Norte No. 2604 Xonaca Puebla, PUEBLA, 72280
            Mexico</span
          >
        </div>
        <div class="dedicamos" style="margin-top: auto">
          Nos dedicamos a proporcionar herramientas simples, seguras y
          convenientes para la gestión y cálculo de préstamos. A través de
          nuestra aplicación, puede calcular fácilmente el monto del préstamo,
          los planes de pago y los diferentes costos asociados, haciendo que el
          proceso de préstamo sea más claro y transparente. Ya sea que esté
          solicitando un préstamo por primera vez o si ya tiene experiencia en
          el tema, nuestras herramientas le ayudarán a gestionar su préstamo de
          manera eficiente y establecer un plan de pagos adecuado, asegurando la
          estabilidad de su situación financiera. Con nuestro algoritmo
          inteligente, le ofrecemos recomendaciones de préstamos personalizadas
          para ayudarle a tomar decisiones financieras más informadas. Nuestra
          misión es ayudar a cada usuario a gestionar sus préstamos de manera
          más eficiente en el entorno financiero actual, utilizando la
          tecnología para hacer el proceso de préstamo más conveniente y seguro.
        </div>
      </div>
    </div>
    <div class="com">
      <div class="tiene">
        Si tiene alguna pregunta o comentario, por favor contáctenos a través de
      </div>
      <div class="servicio">servicio@textipunto.com</div>
      <div class="tiene" style="margin-bottom: 0">
        y nos encargaremos de resolverlo lo antes posible.
      </div>
    </div>
    <div class="eliminar" @click="onClick">Quiero eliminar mis datos.</div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Nosotros',
  components: {},
  data() {
    return {
      index: 1,
      phone: '',
    };
  },
  methods: {
    onClick() {
      this.$router.push({ path: '/eliminardatos' });
    },
  },
};
</script>

<style scoped>
.Nosotros {
  padding: 40px 360px;
  background-color: white;
  display: flex;
}
.texti {
  width: 294px;
  height: 440px;
  margin-right: 40px;
}
.right {
  display: flex;
  flex-direction: column;
}
.right img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Punto {
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  margin-bottom: 10px;
}
.Xonaca {
  font-weight: 400;
  font-size: 20px;
  color: #6450f0;
  display: flex;
  align-items: center;
}
.dedicamos {
  font-weight: 400;
  font-size: 20px;
  color: #565656;
}
.com {
  padding: 40px 360px;
}
.tiene {
  font-size: 24px;
  color: #565656;
  margin-bottom: 10px;
  text-align: center;
}
.servicio {
  font-size: 32px;
  color: #6450f0;
  margin-bottom: 10px;
  text-align: center;
}
.eliminar {
  padding: 40px 360px;
  background-color: white;
  font-weight: 400;
  font-size: 24px;
  color: #ff0000;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
}
</style>
