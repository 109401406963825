<template>
  <div class="Productos">
    <div class="top">
      <img src="@/assets/icon.png" class="icon" />
      <div class="title">
        <div>AyudaPréstamo</div>
        <p>
          Resolvemos sus problemas de crédito, de manera profesional, rápida y
          segura.
        </p>
      </div>
      <div class="ahora">Usar ahora</div>
    </div>
    <div class="desc">
      <div class="desc-title">
        AyudaPréstamo: Tu herramienta ideal para gestionar tus finanzas
      </div>
      <p class="subtitle">
        AyudaPréstamo es tu asistente financiero perfecto, que te ayuda a
        gestionar y optimizar tu flujo de dinero de manera sencilla. Con nuestra
        calculadora de préstamos, podrás calcular con precisión los planes de
        pago futuros, entender la estructura de costos de diferentes opciones y
        tomar decisiones financieras informadas.
      </p>
      <div class="Principales">Principales características:</div>
      <div class="subtitle">
        <p class="sub-li">
          Cálculo completo: Al ingresar el monto, la tasa de interés y el plazo,
          obtendrás rápidamente un plan de pagos detallado para ayudarte a tomar
          decisiones más acertadas.
        </p>
        <p class="sub-li">
          Simulación flexible: Ya sea que planees un pago a largo plazo o a
          corto plazo, puedes ajustar y simular fácilmente en nuestra
          plataforma, pronosticando con precisión el monto de cada pago.
        </p>
        <p class="sub-li">
          Gestión conveniente: Facilita el seguimiento de tu progreso de pagos,
          asegurando que puedas gestionar tus planes financieros a tiempo.
        </p>
        <p class="sub-li">
          Optimización financiera: Mientras planificas tus gastos, AyudaPréstamo
          te proporciona consejos personalizados que hacen que tus objetivos
          financieros sean más controlables. Comienza a usar AyudaPréstamo, deja
          que te ayudemos a planificar mejor tus finanzas futuras, tomar
          decisiones más racionales y aumentar tu libertad financiera.
        </p>
      </div>
    </div>
    <div class="step">
      <div class="step-item">
        <img src="../assets/xinxi.png" class="img1" />
        <span>Llenar la información financiera</span>
      </div>
      <img src="../assets/jiantou.png" class="jiantou" />
      <div class="step-item">
        <img src="../assets/jihua.png" class="img1" />
        <span>Generar el plan de pagos</span>
      </div>
      <img src="../assets/jiantou.png" class="jiantou" />
      <div class="step-item">
        <img src="../assets/zhifu.png" class="img1" />
        <span>Pagar según el plan establecido</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Productos',
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.top {
  padding: 40px 360px;
  background-color: white;
  display: flex;
  align-items: center;
}
.icon {
  width: 120px;
  height: 120px;
  margin-right: 40px;
}
.title div {
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  margin-bottom: 10px;
}
.title p {
  font-weight: 400;
  font-size: 20px;
  color: #565656;
}
.ahora {
  margin-left: auto;
  width: 160px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: #6450f0;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
.desc {
  padding: 40px 360px;
}
.desc-title {
  font-weight: 600;
  font-size: 32px;
  color: #6450f0;
  margin-bottom: 15px;
}
.subtitle {
  font-weight: 400;
  font-size: 20px;
  color: #565656;
  margin-bottom: 24px;
}
.Principales {
  font-weight: 600;
  font-size: 24px;
  color: #565656;
  margin-bottom: 10px;
}
.sub-li {
  padding-left: 20px;
  position: relative;
  margin-bottom: 5px;
}
.sub-li::after {
  content: ' ';
  width: 6px;
  height: 6px;
  background-color: #000000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.step {
  padding: 40px 360px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.step-item {
  width: 340px;
  height: 320px;
  background: #f5faff;
  border-radius: 16px;
  font-weight: 600;
  font-size: 24px;
  color: #565656;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.step-item .img1 {
  width: 70px;
  height: 80px;
}
.step-item .img2 {
  width: 93px;
  height: 80px;
}
.step-item .img3 {
  width: 78px;
  height: 80px;
}
.step-item span {
  margin-top: 40px;
}
.jiantou {
  width: 64px;
  height: 42px;
}
</style>
