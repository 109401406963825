<template>
  <div>
    <div class="hread">
      <img src="@/assets/logo.png" class="logo" />
      <img class="banner" src="@/assets/banner.png" alt="" />
    </div>
    <div class="tabs-box" ref="myTabs">
      <div
        class="tab-item"
        :class="tabIndex === index ? 'tab-action' : ''"
        v-for="(item, index) in tabs"
        :key="item.path"
        @click="change(index, item.path)"
      >
        {{ item.title }}
      </div>
    </div>

    <RouterView></RouterView>

    <div class="footer">
      © 2024 Texti Punto, S.A. de C.V. Todos los derechos reservados.
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      tabs: [
        {
          title: 'Nuestros Productos',
          path: '/',
        },
        {
          title: 'Sobre Nosotros',
          path: '/sobrenosotros',
        },
        {
          title: 'Política de Privacid',
          path: '/politicadeprivacidad',
        },
        {
          title: 'Soporte Técnico',
          path: '/soportetecnico',
        },
      ],
      tabIndex: 0,
      offsetTop: 0,
    };
  },
  mounted() {
    const element = this.$refs.myTabs;
    this.offsetTop = element.offsetTop;
  },
  watch: {
    $route: {
      handler: function (to) {
        localStorage.setItem('routeIndex', to.path);
      },
    },
  },
  created() {
    const path = localStorage.getItem('routeIndex');
    if (path) {
      if (path === '/') {
        this.tabIndex = 0;
      } else if (path === '/sobrenosotros') {
        this.tabIndex = 1;
      } else if (path === '/politicadeprivacidad') {
        this.tabIndex = 2;
      } else if (path === '/eliminardatos') {
        this.tabIndex = 1;
      } else if (path === '/soportetecnico') {
        this.tabIndex = 3;
      }
    }
  },
  methods: {
    change(index, path) {
      this.tabIndex = index;
      this.$router.push({ path: path });
      window.scrollTo({
        top: this.offsetTop,
        behavior: 'instant',
      });
    },
  },
};
</script>

<style scoped>
.hread {
  padding: 40px 360px;
  padding-bottom: 0;
  height: 720px;
  background: linear-gradient(135deg, #9696fa 0%, #6450f0 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}
.banner {
  width: 1099px;
  height: 720px;
  position: absolute;
  top: 0;
}
.logo {
  width: 240px;
  height: 32px;
  position: absolute;
  top: 40px;
  left: 360px;
}

.tabs-box {
  background: linear-gradient(135deg, #8a85f8 0%, #6450f0 100%);
  padding: 0 360px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 24px;
  color: #6450f0;
  gap: 10px;
  z-index: 9;
  position: sticky;
  top: 0;
  margin-top: -64px;
}
.tab-item {
  flex: 1;
  height: 64px;
  background: #7062f3;
  border-radius: 8px 8px 0px 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab-action {
  background: #ffffff;
  color: #6450f0;
}
.footer {
  height: 64px;
  line-height: 64px;
  background: #6450f0;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
</style>
